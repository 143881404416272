// Colors
$rewardsOrange: #f47920;
$labelYellow: #ffda00;
$charcoal: #39464e;
$charcoalTint: #ebeced;
$errorRed: #ff3b30;
$primaryTextColor: #454545;
$hintColor: #666666;
$fakeKeyboardBg: #d1d5db;
$fakeKeyboardButtonBg: #fcfcfe;
$page-background: #f5f6f6;
$green-light-90: #e9f2ed; // To do: Import from DS

// Dimensions
$marginTiny: 0.5em;
$marginSmall: 1em;
$marginMedium: 2em;
$marginBig: 3em;

// Transition
$defaultTransitionDuration: 100ms;
$defaultTransitionType: linear;

// Fixed Heights
$footerHeight: 226px; // To do: Import from DS
